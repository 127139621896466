import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from '../navigation-bar/menu';
import { AuthService } from '../services/auth.service';
import { SUPPORTED_LANGUAGES, TranslationService } from '../shared/translations/translation.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public showChatbot = environment.showChatbot;

  public constructor(
    private readonly authService: AuthService,
    private readonly translationService: TranslationService,
    private readonly translateService: TranslateService
  ) {
    this.actualLanguage = this.translateService.currentLang;
  }

  public ngOnInit(): void {}

  public get token() {
    return this.authService.token;
  }

  public get user() {
    return this.authService.user;
  }

  public login(): void {
    this.authService.checkUserVisitedAbout();
  }

  public logout(): void {
    this.authService.logoutAndRedirect('/');
  }

  public get isAuthorized(): boolean {
    return this.authService.isAuthorized;
  }

  public supportedLanguages = SUPPORTED_LANGUAGES;
  private actualLanguage;

  public get currentLanguage(): string {
    return this.actualLanguage;
  }

  public changeLanguage(language: SUPPORTED_LANGUAGES): void {
    this.translationService.proceedUsing(language);
    this.actualLanguage = language;
  }

  public listItems: ListItem[] = [
    { title: 'header.mainMenu.introduction', link: 'about', children: [] },
    { title: 'header.mainMenu.dashboard', link: 'dashboard', children: [] },
    { title: 'header.mainMenu.resumes', link: 'resumes', children: [] },
    ...(environment.showCertificates
      ? [{ title: 'header.mainMenu.certificates', link: 'certificates', children: [] }]
      : []),
    ...(environment.showGuidelines ? [{ title: 'header.mainMenu.guidelines', link: 'guidelines', children: [] }] : []),
    ...(environment.showCv ? [{ title: 'CV Generator', link: 'cv-generator', children: [] }] : []),
    ...(environment.showOnboardingChecklist
      ? [{ title: 'onboardingChecklist.title', link: 'onboarding-checklist', children: [] }]
      : [])
  ];
}
