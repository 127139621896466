import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { LoginComponent } from './login/login.component';
import { NotFoundPageComponent } from './page-not-found/page-not-found.component';
import { ResumesComponent } from './pages/resumes/resumes.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AppOfflineComponent } from './pages/app-offline/app-offline.component';
import { TimeCheckGuard } from './guards/timecheck.guard';
import { ServerErrorComponent } from './shared/components/server-error/server-error.component';
import { EmailNotSupportedComponent } from './shared/components/email-not-supported/email-not-supported.component';
import { IntroComponent } from './pages/intro/intro.component';
import { NewGuidelinesComponent } from './pages/new-guidelines/new-guidelines.component';
import { CvGeneratorComponent } from './pages/cv-generator/cv-generator.component';
import { OnboardingChecklistComponent } from './pages/onboarding-checklist/onboarding-checklist.component';

const routes: Routes = [
  { path: 'offline', component: AppOfflineComponent },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'about',
        component: IntroComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        canMatch: [TimeCheckGuard]
      },
      {
        path: 'resumes',
        component: ResumesComponent,
        canActivate: [AuthGuard],
        canMatch: [TimeCheckGuard]
      },
      ...(environment.showCertificates
        ? [
            {
              path: 'certificates',
              component: CertificatesComponent,
              canActivate: [AuthGuard],
              canMatch: [TimeCheckGuard]
            }
          ]
        : []),
      ...(environment.showGuidelines
        ? [
            {
              path: 'guidelines',
              component: NewGuidelinesComponent,
              canActivate: [AuthGuard],
              canMatch: [TimeCheckGuard]
            }
          ]
        : []),
      ...(environment.showGuidelines
        ? [
            {
              path: 'guidelines/:title',
              component: NewGuidelinesComponent,
              canActivate: [AuthGuard],
              canMatch: [TimeCheckGuard]
            }
          ]
        : []),
      ...(environment.showCv
        ? [
            {
              path: 'cv-generator',
              component: CvGeneratorComponent,
              canActivate: [AuthGuard],
              canMatch: [TimeCheckGuard]
            },
            { path: 'cv-generator#personalDetail', component: CvGeneratorComponent },
            { path: 'cv-generator#summary', component: CvGeneratorComponent },
            { path: 'cv-generator#educations', component: CvGeneratorComponent },
            { path: 'cv-generator#skills', component: CvGeneratorComponent },
            { path: 'cv-generator#certifications', component: CvGeneratorComponent },
            { path: 'cv-generator#languages', component: CvGeneratorComponent },
            { path: 'cv-generator#experiences', component: CvGeneratorComponent }
          ]
        : []),
      ...(environment.showOnboardingChecklist
        ? [
            {
              path: 'onboarding-checklist',
              component: OnboardingChecklistComponent,
              canActivate: [AuthGuard],
              canMatch: [TimeCheckGuard]
            }
          ]
        : []),
      { path: 'server-error', component: ServerErrorComponent },
      { path: 'email-not-supported', component: EmailNotSupportedComponent },
      { path: '**', component: NotFoundPageComponent }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
